import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { ThemeContext } from '../../../shared/Services/handler';
import CommonStatus from '../CommonStatus';
import Heading1 from '../Typography/Heading1';

const useStyles = makeStyles({
    loadingarea: {
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loading_failed_status: {
        maxWidth: '260px',
        marginTop: '10rem'
    },
    tryLoading: {
        maxWidth: '260px'
    },
    btn_class: {
        width: '100% !important'
    }
});

const CommonLoading = ({ className }) => {
    const classes = useStyles();
    const { message } = useContext(ThemeContext);
    const pathname = usePathname();
    const router = useRouter();
    const [state, setState] = useState(false);
    const [tryReload, setTryReload] = useState(false);

    setTimeout(() => {
        setState(true);
    }, 10000);

    const tryToReload = () => {
        setTryReload(true);

        setTimeout(() => {
            router?.reload(pathname);
        }, 1000);
    };

    return (
        <div className={`common_loading ${className} ${classes.loadingarea}`}>
            {message !== 'Unexpected error' ? (
                <>
                    <CircularProgress />
                    <Heading1 text="Loading" centerText />
                    {state && (
                        <CommonStatus
                            buttonText={'Reload page'}
                            className={classes.tryLoading}
                            btn_class={classes.btn_class}
                            onClick={() => tryToReload()}
                            subHeading={`This seems to be taking a long time. 
                                   
                                   You could try reloading the page using the button below.`}
                        />
                    )}
                </>
            ) : (
                <>
                    {tryReload && <CircularProgress className={classes.loading_failed_status} />}
                    <CommonStatus
                        buttonText={'Refresh page'}
                        className={tryReload ? classes.tryLoading : classes.loading_failed_status}
                        btn_class={classes.btn_class}
                        heading={`Loading failed`}
                        onClick={() => tryToReload()}
                        subHeading={'Something went wrong. Please refresh the page and try again.'}
                    />
                </>
            )}
        </div>
    );
};

CommonLoading.propTypes = { className: PropTypes.string };

export default CommonLoading;
